
import Vue from 'vue'

export default Vue.extend({
  name: 'CrudEntfernen',

  data: () => {
    const actions: any = {
      awards: 'deleteAward',
      users: 'deleteUser',
      projects: 'deleteProjectMain',
      projectDetails: 'deleteProjectDetail',
    }
    return {
      actions,
      award: null,
      dots: '.',
      interv: 0,
      project: null,
      projectDetail: null,
      user: null,
    }
  },

  computed: {
    id () {
      return this.$route.params.id
    },
    currentAward() {
      return this.$store.state.currentAward
    },
    titles() {
      return this.$store.state.titles
    },
    type() {
      return this.$route.params.type
    },
  },


  methods: {
    animateDots () {
      if (this.dots.length <= 4) {
        this.dots += '.'
      } else {
        this.dots = '.'
      }
    },
    deleteObject() {
      const yes = confirm("Sind Sie sich sicher?")
      if (yes) {
        this.interv = window.setInterval(this.animateDots, 200)
        const action: string = this.actions[this.type]
        let options: any = this.id
        if (this.type === 'projectDetails') {
          const { awardType, year } = this.currentAward
          options = { 
            id: this.id,
            awardType,
            year,
          }
        }
        this.$store
          .dispatch(action, options)
          .then(res => {
            console.log(action +' -> res:', res)
            this.$router.push('/admin/liste/' + this.type)
          });
        }
    },
  },

  mounted () {
    switch (this.$route.params.type) {
      case 'awards':
        return this.$store.dispatch('fetchAward', this.$route.params.id)
          .then(a => this.award = a)
      case 'projectDetails':
        return this.$store.dispatch('fetchProject', this.$route.params.id)
          .then(p => this.projectDetail = p)
      case 'users':
        return this.$store.dispatch('fetchUser', this.$route.params.id)
          .then(u => this.user = u)
      default:
        // projects
        return this.$store.dispatch('fetchProject', this.$route.params.id)
          .then(p => this.project = p)
    }
  },

  destroyed () {
    clearInterval(this.interv)
  },

});
